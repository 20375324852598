import axios from "axios";
import { useState, useEffect } from "react";

let footerMenu = {};
const useFooter = () => {
  const [footer, setFooter] = useState({ footer: null, script: null });

  useEffect(() => {
    const lang = localStorage.getItem("language_type");

    axios
      .get(
        `https://www.ikea.com/ae/${
          !lang ? "en" : lang
        }/header-footer/footer-lazy-fragment.html`
      )
      .then((resp) => {
        footerMenu.footer = resp?.data;
        setFooter({ ...footerMenu });
      });

    axios
      .get(
        `https://www.ikea.com/ae/${
          !lang ? "en" : lang
        }/header-footer/script-fragment-recursive.html`
      )
      .then((resp) => {
        footerMenu.script = resp?.data;
        setFooter({ ...footerMenu });
      });
  }, []);

  return footer;
};

export default useFooter;

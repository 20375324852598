import React from "react";
import { useTranslation } from "react-i18next";
import Style from "./CheckoutFor.module.scss";
import moment from "moment";

const CheckoutFor = ({ data }) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <div className={Style.gift_to}>
        <div className={Style.gift_to_title}>{t("Gift_to")}</div>
        <div className={Style.recipient}>
          <div>
            {t("Recipient_Name2")}:&nbsp;<span>{data?.name}</span>
          </div>
          <div>
            {t("Recipient_mail2")}:&nbsp;<span>{data?.email}</span>
          </div>
          <div>
            {t("Recipient_Number2")}:&nbsp;<span>{data?.mobile}</span>
          </div>
          <div>
            {t("Arrive_ASAP")}:&nbsp;
            <span>
              {new Date().toDateString() === new Date(data?.date).toDateString()
                ? t("Now")
                : moment(data?.date, "yyyy/MM/DD").format("DD/MM/yyyy")}
            </span>
          </div>
        </div>
      </div>
      <div className={Style.card_block}>
        <figure className={Style.card_block_img}>
          <img src={data?.gift_card?.gift_card_full_url} alt="Gift Card" />
        </figure>
        <div className={Style.text_block}>
          <div className={Style.text_block_greetings}>{data?.message}</div>
          <div className={Style.text_block_amount}>
            <div>
              <sup>{t("DHS")}</sup>
              {parseInt(data.amount)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutFor;

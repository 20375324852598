import { useEffect, useState } from "react";
import useWindowDimensions from "./useScreen";
import platform from "platform";
import { getInitiatePurchase } from "../store/slices/initiatePurchaseSlice";
import { useStorePurchase } from "./useStorePurchase";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

let paymentApi;

const useCardData = (params) => {
  const { t } = useTranslation(["common"]);

  const [show, setShow] = useState(false);
  const [iFrame, setIFrame] = useState("");
  const [paymentError, setPaymentError] = useState("");

  const [loadingShimmer, setLoadingShimmer] = useState(false);

  const dispatch = useDispatch();

  const { slug } = useParams();
  useEffect(() => {
    fetchCardDetails(slug);
  }, []);

  useEffect(() => {}, [paymentError]);

  const { fetchCardDetails, details } = useStorePurchase();

  const { height, width } = useWindowDimensions();

  const timezoneOffset = new Date().getTimezoneOffset();

  var userLanguage = window.navigator.userLanguage || window.navigator.language;

  useEffect(() => {
    if (params) {
      paymentConfigure(params);
    }
  }, [params]);

  const initiatePurchase = async (params) => {
    const resultAction = await dispatch(getInitiatePurchase(params));

    if (getInitiatePurchase.fulfilled.match(resultAction)) {
      setShow(true);
      setLoadingShimmer(false);
      document.getElementById("payButton").classList.remove("loading");
      setIFrame(resultAction?.payload?.data?.data?.iframe);
    } else {
      if (resultAction?.payload?.code === 500) {
        initiatePurchase(params);
      }
    }
  };

  const paymentConfigure = (session_id) => {
    if (window.PaymentSession !== undefined) {
      paymentApi = window.PaymentSession;
      paymentApi.configure({
        session: session_id, // keep payment session in a global variable
        fields: {
          // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
          card: {
            number: "#card-number",
            securityCode: "#security-code",
            expiryMonth: "#expiry-month",
            expiryYear: "#expiry-year",
            nameOnCard: "#cardholder-name",
          },
        },

        //SPECIFY YOUR MITIGATION OPTION HERE
        frameEmbeddingMitigation: ["javascript"],
        callbacks: {
          initialized: (response) => {
            // HANDLE INITIALIZATION RESPONSE
          },
          formSessionUpdate: async (response) => {
            // HANDLE RESPONSE FOR UPDATE SESSION
            if (response.status) {
              if ("ok" === response.status) {
                const payload = {
                  order_id: details.id,
                  session_id: details.session_id,
                  browser: platform.name,
                  colorDepth: window.screen.colorDepth,
                  javaEnabled: false,
                  language: userLanguage,
                  screenHeight: height,
                  screenWidth: width,
                  timeZone: timezoneOffset,
                  languageType: localStorage.getItem("language_type"),
                };

                initiatePurchase(payload);

                //check if the security code was provided by the user
                if (response.sourceOfFunds.provided.card.securityCode) {
                  setPaymentError("");
                }

                //check if the user entered a Mastercard credit card
                if (
                  response.sourceOfFunds.provided.card.scheme === "MASTERCARD"
                ) {
                  setPaymentError("");
                }
              } else if ("fields_in_error" === response.status) {
                if (response.errors.cardNumber) {
                  injectErrorToForm(t("card_num_missing"));
                  document
                    .getElementById("payButton")
                    .classList.remove("loading");
                }
                if (response.errors.expiryYear) {
                  injectErrorToForm(t("Expiry_year"));
                  document
                    .getElementById("payButton")
                    .classList.remove("loading");
                }
                if (response.errors.expiryMonth) {
                  injectErrorToForm(t("Expiry_month"));
                  document
                    .getElementById("payButton")
                    .classList.remove("loading");
                }
                if (response.errors.securityCode) {
                  injectErrorToForm(t("Security_code"));
                  document
                    .getElementById("payButton")
                    .classList.remove("loading");
                }
              } else if ("request_timeout" === response.status) {
                injectErrorToForm(
                  "Session update failed with request timeout: " +
                    response.errors.message
                );
              } else if ("system_error" === response.status) {
                injectErrorToForm(
                  "Session update failed with system error: " +
                    response.errors.message
                );
              }
            } else {
              injectErrorToForm("Session update failed: " + response);
            }
          },
        },
        interaction: {
          displayControl: {
            formatCard: "EMBOSSED",
            invalidFieldCharacters: "REJECT",
          },
        },
      });
    }
  };
  const pay = () => {
    paymentApi.updateSessionFromForm("card");
    document.getElementById("payButton").classList.add("loading");
    setPaymentError("");
  };

  const injectErrorToForm = (error) => {
    setPaymentError(error);
  };

  return {
    pay,
    paymentError,
    show,
    setShow,
    iFrame,
    loadingShimmer,
    setLoadingShimmer,
  };
};

export default useCardData;

import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Style from "./GiftCardsPersonalise.module.scss";

const GiftCardsPersonalise = ({ formik }) => {
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    console.log(formik.values.mobile);
  }, [formik]);

  return (
    <section className={Style.gift_cards_personalise}>
      <div className="container">
        <div className={`sm_container ${Style.wrapper}`}>
          <div className="text-md-center">
            <h1 className="h6">{t("PERSONALISE_GIFT_CARD")}</h1>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group text-start">
                  <Form.Label>{t("Recipient_Name")}</Form.Label>
                  <Form.Control
                    type="text"
                    id="fullName"
                    placeholder={t("Full_Name")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    name="fullName"
                  />
                </Form.Group>
                {formik.touched.fullName && formik.errors.fullName ? (
                  <div className="formikError">{formik.errors.fullName}</div>
                ) : null}
                <Form.Group className="form-group text-start">
                  <Form.Label>{t("Recipient_mail")}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t("E_mail")}
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </Form.Group>
                {formik.touched.email && formik.errors.email ? (
                  <div className="formikError">{formik.errors.email}</div>
                ) : null}
                <Form.Group className="form-group text-start">
                  <Form.Label>{t("Recipient_Number")}</Form.Label>
                  <Form.Control
                    type="text"
                    id="mobile"
                    placeholder={t("Mobile_Number")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                    name="mobile"
                    maxLength={13}
                    minLength={9}
                  />
                </Form.Group>
                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className="formikError">{formik.errors.mobile}</div>
                ) : null}
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group text-start">
                  <Form.Label>{t("Personal_Message")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t("Enter_message")}
                    id="message"
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    maxLength="300"
                  />
                  <small>{t("characters_left")}</small>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiftCardsPersonalise;

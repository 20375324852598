import { useEffect } from "react";
import { useStorePurchase } from "../../logic/useStorePurchase";
import GiftCardsDelivery from "./GiftCardsDelivery";
import GiftCardsDesign from "./GiftCardsDesign";
import GiftCardsPersonalise from "./GiftCardsPersonalise";
import GiftCardsValue from "./GiftCardsValue";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useSelector, useDispatch } from "react-redux";
import { saveFormValue } from "../../store/slices/formPersistSlice";
import { useParams, useNavigate } from "react-router-dom";

const GiftCards = () => {
  const dispatch = useDispatch();
  const { lang: langSlug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (langSlug === "ar") {
      localStorage.setItem("language_type", "ar");
    } else if (langSlug === "en") {
      localStorage.setItem("language_type", "en");
    }
  }, []);
  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(langSlug);
  }, [langSlug]);

  useEffect(() => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    }
  }, []);

  const { formSubmit, loader } = useStorePurchase();

  const { t } = useTranslation(["common"]);

  var numbers = /^\d+\.?\d*$/;

  const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  const formValue = useSelector((state) => state.formData);

  const formik = useFormik({
    initialValues:
      Object.keys(formValue.formData).length === 0
        ? {
            fullName: "",
            email: "",
            mobile: "",
            giftCard: "",
            amount: "",
            message: "",
            type: 1,
            date: moment().add(1, "d").toDate(),
          }
        : formValue.formData,

    validationSchema: Yup.object({
      giftCard: Yup.string().required(t("select_Card")),
      type: Yup.string().required(t("Select_type")),
      fullName: Yup.string().required(t("Name_Required")),
      email: Yup.string()
        .email(t("Invalid_email"))
        .required(t("Email_Required")),
      amount: Yup.string()
        .matches(numbers, t("Be_A_Number"))
        .required(t("Amount_Required")),
      mobile: Yup.string()
        .matches(phoneRegExp, t("Invalid_mobile"))
        .required(t("Mobile_required")),
    }),
    onSubmit: (values) => {
      dispatch(saveFormValue(values));

      let scrptEl = document.createElement("script");
      scrptEl.src =
        "https://ap-gateway.mastercard.com/form/version/58/merchant/TEST804104000/session.js";
      scrptEl.async = true;
      document.body.appendChild(scrptEl);
      let dateData = {
        ...values,
        date:
          formik.values.type === 1
            ? moment().format("YYYY-MM-DD")
            : moment(formik.values.date).format("YYYY-MM-DD"),
      };
      formSubmit(dateData);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <GiftCardsDesign formik={formik} />
      <GiftCardsValue formik={formik} />
      <GiftCardsPersonalise formik={formik} />
      <GiftCardsDelivery formik={formik} loader={loader} />
    </form>
  );
};

export default GiftCards;

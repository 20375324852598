import React, { useEffect, useState } from "react";
import Style from "./GiftCardsDesign.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import { useGiftCard } from "../../../logic/useGiftCard";
import { useTranslation } from "react-i18next";

const GiftCardsDesign = ({ formik }) => {
  const [filteredCards, setFilteredCards] = useState([]);

  const { t } = useTranslation(["common"]);
  const { giftCardData } = useGiftCard();

  useEffect(() => {
    const switcher = document.getElementsByClassName("switcher")[0];
    if (
      switcher.attributes?.dir?.value === "rtl" &&
      giftCardData.length !== 0
    ) {
      const filteredArr = giftCardData.filter((value) => value.card_type === 2);

      setFilteredCards(filteredArr);
    } else if (
      switcher.attributes?.dir?.value === undefined ||
      switcher.attributes?.dir?.value === "ltr"
    ) {
      const filteredArr = giftCardData.filter((value) => value.card_type === 1);
      setFilteredCards(filteredArr);
    }
  }, [giftCardData]);

  const handleError = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return <div className={Style.cardError}>{formik.errors.giftCard}</div>;
  };

  return (
    <section className={Style.gift_cards}>
      <div className="container">
        <div className="text-md-center">
          {giftCardData && <h1 className="h6">{t("CHOOSE_YOUR_DESIGN")}</h1>}
        </div>
        <Swiper
          scrollbar={{
            el: ".swiper-scrollbar",
            draggable: true,
            dragSize: 98,
          }}
          navigation={true}

          allowTouchMove={true}
          spaceBetween={25}
          slidesPerView={1.5}
          modules={[Scrollbar, Navigation]}
          className={Style.gift_cards_swiper}
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 25,
              allowTouchMove:false,
            },
          }}
        >
          {giftCardData &&
            giftCardData.length > 0 &&
            filteredCards.map((value, i) => {
              return (
                <SwiperSlide key={value.id}>
                  <div className={Style.gift_card_box} key={value.id}>
                    <input
                      type="radio"
                      name="giftCard"
                      id={value.id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.id || value.id}
                      checked={
                        formik.values.giftCard == value.id ? true : false
                      }
                    />
                    <label htmlFor={value.id}>
                      <figure>
                        <img src={value.gift_card_full_url} alt="Gift Card" />
                      </figure>
                    </label>
                  </div>
                </SwiperSlide>
              );
            })}

          <div
            className={`swiper-scrollbar ${Style.gift_cards_swiper_scrollbar}`}
          ></div>
          {formik.touched.giftCard && formik.errors.giftCard
            ? handleError()
            : null}
        </Swiper>
      </div>
    </section>
  );
};

export default GiftCardsDesign;

import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useListAmount } from "../../../logic/useListAmount";
import Style from "./GiftCardsValue.module.scss";
import { useTranslation } from "react-i18next";

const GiftCardsValue = ({ formik }) => {
  const { listAmountData } = useListAmount();
  const [inputValue, setInputValue] = useState("");
  const [inputActive, setInputActive] = useState("");

  const inputValueClick = (e) => {
    setInputValue(e.target.value);
    setInputActive(e.target.value);
  };

  useEffect(() => {
    console.log(inputValue, formik.values.amount);
  }, [inputValue, formik]);

  const { t } = useTranslation(["common"]);

  return (
    <section className={Style.gift_cards_value}>
      <div className="container">
        <div className={`sm_container ${Style.wrapper}`}>
          <div className="text-md-center">
            <h1 className="h6">{t("CHOOSE_YOUR_DESIGN")}</h1>
          </div>
          <div className={`row ${Style.custom_row}`}>
            <div className="col-md-6">
              <label htmlFor="" className={`h6 ${Style.title_label}`}>
                {t("Select_amount")}
              </label>
              <div className={Style.select_amount}>
                {listAmountData &&
                  listAmountData.length > 0 &&
                  listAmountData.slice(0, 3).map((value, index) => {
                    return (
                      <Button
                        className={
                          inputActive === value.amount
                            ? `btn btn-secondary ${Style.select_amount_btn} ${Style.active}`
                            : `btn btn-secondary ${Style.select_amount_btn} ${
                                formik.values.amount == value.amount &&
                                Style.active
                              }`
                        }
                        value={value.amount}
                        onClick={(e) => {
                          formik.setFieldValue(
                            "amount",
                            parseInt(value.amount)
                          );
                          inputValueClick(e);
                        }}
                        key={index}
                      >
                        {parseInt(value.amount)}
                      </Button>
                    );
                  })}
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="amount" className={`h6 ${Style.title_label}`}>
                {t("enter_amount")}
              </label>
              <Form.Group
                className="form-group text-start text-black"
                controlId="amount"
              >
                <Form.Control
                  type="text"
                  name="amount"
                  value={parseInt(formik.values.amount) || inputValue}
                  maxLength={4}
                  placeholder={t("Enter_Amount")}
                  onChange={(e) => {
                    setInputActive(e.target.value);
                    formik.handleChange(e);
                    setInputValue(e.target.value);
                  }}
                />
              </Form.Group>
              {formik.touched.amount && formik.errors.amount ? (
                <div className="formikError">{formik.errors.amount}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiftCardsValue;

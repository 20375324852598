import React, { useEffect } from "react";
import Style from "./CheckoutPayment.module.scss";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import useCardData from "../../../logic/useCardData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckoutPayment = ({ data, paymentError }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  const { pay, loadingShimmer, setLoadingShimmer } = useCardData(
    data?.session_id
  );
  useEffect(() => {}, [paymentError]);

  return (
    <div className={Style.payment_detail}>
      <div className={`h5 ${Style.payment_detail_title}`}>
        {t("ENTER_PAYMENT_DETAILS")}
      </div>

      <div className={`row ${Style.custom_row}`}>
        <div className="col-12">
          <FloatingLabel
            controlId="cardNumber"
            label="Card number"
            className={Style.form_group}
          >
            <Form.Control
              id="card-number"
              readOnly
              className={Style.form_control}
            />
          </FloatingLabel>
        </div>
        <div className="col-12">
          <FloatingLabel
            controlId="cardHolder"
            label="Card holder name"
            className={Style.form_group}
          >
            <Form.Control
              type="text"
              id="cardholder-name"
              readOnly
              className={Style.form_control}
            />
          </FloatingLabel>
        </div>
        <div className="col-6">
          <FloatingLabel
            controlId="Month"
            label="Month"
            className={Style.form_group}
          >
            <Form.Select
              aria-label="Month"
              className={Style.form_control}
              id="expiry-month"
              readOnly
            >
              <option value="1">Jan</option>
              <option value="2">Feb</option>
              <option value="3">Mar</option>
              <option value="4">Apr</option>
              <option value="5">May</option>
              <option value="6">Jun</option>
              <option value="7">Jul</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </Form.Select>
          </FloatingLabel>
        </div>
        <div className="col-6">
          <FloatingLabel
            controlId="Year"
            label="Year"
            className={Style.form_group}
          >
            <Form.Select
              aria-label="Year"
              className={Style.form_control}
              id="expiry-year"
              readOnly
            >
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2026">2027</option>
              <option value="2026">2028</option>
              <option value="2026">2029</option>
              <option value="2026">2030</option>
            </Form.Select>
          </FloatingLabel>
        </div>
        <div className="col-6">
          <FloatingLabel
            controlId="Security"
            label="Security"
            className={Style.form_group}
          >
            <Form.Control
              type="text"
              className={Style.form_control}
              id="security-code"
              readOnly
              maxLength="4"
            />
          </FloatingLabel>
        </div>

        <div className="col-12">
          <div className={Style.amount_summary}>
            {t("Total_amount")}
            <span>
              {" "}
              <sup>{t("DHS")}</sup>
              {parseInt(data.amount)}{" "}
            </span>
          </div>
        </div>

        <div className="col-6">
          <Button
            variant="tertiary"
            type="button"
            className={Style.payment_btn}
            onClick={() => {
              navigate("/");
              window.location.reload();
            }}
          >
            {t("CANCEL")}
          </Button>
        </div>

        <div className="col-6">
          <Button
            variant="primary"
            type="button"
            className={
              loadingShimmer
                ? `${Style.payment_btn} loading`
                : Style.payment_btn
            }
            id="payButton"
            onClick={() => {
              pay("card");
              setLoadingShimmer(true);
            }}
          >
            {t("PAY")}
          </Button>
        </div>
        <small className={Style.error}>
          {paymentError && <p>{paymentError}</p>}
        </small>
      </div>
    </div>
  );
};

export default CheckoutPayment;

import React, { Suspense, lazy } from "react";
import "../styles/common.scss";

import { Routes, Route } from "react-router-dom";

import GiftCardsPage from "../pages/GiftCards";
import CheckoutPage from "../pages/Checkout";

const App = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<GiftCardsPage />} />
        <Route path="/:lang" element={<GiftCardsPage />} />
        <Route path="/checkout/:slug/:lang" element={<CheckoutPage />} />
      </Routes>
    </Suspense>
  );
};

export default App;

import { useState } from "react";
import { getStorePurchase } from "../store/slices/storePurchaseSlice";
import { useDispatch } from "react-redux";
import { getorderDetails } from "../store/slices/orderDetailsSlice";
import { useNavigate } from "react-router-dom";

export const useStorePurchase = () => {
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formSubmit = async (data) => {
    setLoader(true);
    const payload = {
      gift_card_id: data.giftCard,
      amount: data.amount,
      name: data.fullName,
      email: data.email,
      message: data.message,
      type: data.type,
      date: data.date,
      mobile: data.mobile,
    };
    const resultAction = await dispatch(getStorePurchase(payload));

    if (getStorePurchase.fulfilled.match(resultAction)) {
      const purchase_id = resultAction?.payload?.data?.data?.id;
      const lang = localStorage.getItem("language_type");
      navigate(`/checkout/${purchase_id}/${lang}`);
      setLoader(false);
    }
  };

  const fetchCardDetails = (id) => {
    dispatch(getorderDetails(id))
      .unwrap()
      .then((value) => {
        setDetails(value?.data?.data);
      });
  };

  return {
    formSubmit,
    fetchCardDetails,
    details,
    loader,
    setLoader,
  };
};

import React, { useEffect, useState } from "react";
import Style from "./Checkout.module.scss";
import CheckoutFor from "./CheckoutFor";
import OrderSummary from "./OrderSummary";
import CheckoutPayment from "./CheckoutPayment";
import { useStorePurchase } from "../../logic/useStorePurchase";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import useCardData from "../../logic/useCardData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

let count = 0;

const Checkout = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();

  const { slug } = useParams();
  const { fetchCardDetails, details } = useStorePurchase();

  const { show, setShow, iFrame, paymentError, loadingShimmer } = useCardData(
    details?.session_id
  );

  useEffect(() => {
    fetchCardDetails(slug);
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (iFrame) {
      const formId = document.getElementById("threedsChallengeRedirectForm");
      const formId2 = document.getElementById("redirectTo3ds1Form");
      if (formId) {
        formId.submit();
      } else if (formId2) {
        formId2.submit();
      }
      const iframeDiv = document.getElementById("challengeFrame");
      const iframeDiv2 = document.getElementById("redirectTo3ds1Frame");
      if (iframeDiv) {
        iframeDiv.addEventListener("load", (e) => {
          if (count > 1) {
            document.getElementById("threedsChallengeRedirect").style.height =
              "200px";
            setShowModal(true);
            document
              .getElementsByClassName("modal-dialog")[0]
              .classList.add("modal-dialog-centered");
          } else count++;
        });
      } else if (iframeDiv2) {
        iframeDiv2.addEventListener("load", (e) => {
          if (count <= 1) {
            document.getElementById("redirectTo3ds1Frame").style.height =
              "100vh";
          }

          if (count > 1) {
            document.getElementById("redirectTo3ds1Frame").style.height =
              "200px";
            setShowModal(true);
            document
              .getElementsByClassName("modal-dialog")[0]
              .classList.add("modal-dialog-centered");
          } else count++;
        });
      }
    }
  }, [iFrame]);

  const handleHide = () => {
    setShow(false);
    navigate("/");
    window.location.reload();
  };

  return (
    <section className={Style.checkout_page}>
      <Modal show={show}>
        {showModal && (
          <Modal.Header closeButton onClick={handleHide}></Modal.Header>
        )}
        {iFrame && <div dangerouslySetInnerHTML={{ __html: iFrame }} />}
      </Modal>
      <div className="container">
        <div className="sm_container">
          <div className="text-start">
            <h1 className="h5">{t("CHECKOUT")}</h1>
          </div>
          <div className={`row ${Style.custom_row}`}>
            <div className="col-md-6">
              <CheckoutFor data={details} />
            </div>
            <div className="col-md-6">
              <OrderSummary data={details} />
              <CheckoutPayment
                data={details}
                paymentError={paymentError}
                loadingShimmer={loadingShimmer}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;

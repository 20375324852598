import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Menu from "../Menu";
import i18n from "../../../i18n";

const CommonLayout = ({ children }) => {
  return (
    <>
      <Menu />
      <div className="switcher">{children}</div>
      <Footer />
    </>
  );
};

export default CommonLayout;

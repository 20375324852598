import React from 'react';
import CommonLayout from '../../components/Layouts/CommonLayout';
import GiftCards from '../../components/GiftCards';

const GiftCardsPage = () => {
  return (
    <CommonLayout>
        <GiftCards />
    </CommonLayout>
  )
}

export default GiftCardsPage